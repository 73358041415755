import styled from "styled-components/macro";

export const Container = styled.div`
  padding-bottom: 4rem;
  /* background: #f9f9f9; */
  background-color: #f5f4f4;
  background-color: #fcfcfc;
  padding-top: 15rem;
  margin-top: -11.5rem;

  @media (min-width: 768px) and (max-width: 920px) {
    padding-top: 12rem;
  }
  @media (min-width: 451px) and (max-width: 767px) {
    padding-top: 11.5rem;
    padding-bottom: 1rem;
  }
  @media (min-width: 320px) and (max-width: 450px) {
    margin-top: -8rem;
    padding-top: 12rem;
  }
`;

export const Heading = styled.h4`
  font-family: Rubik;
  font-size: 35px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: 0em;
  margin: auto;
  text-align: center;
  color: #0b132a;
  font-family: "Rubik", sans-serif;

  @media (min-width: 768px) and (max-width: 920px) {
    font-size: 3rem;
  }
  @media (min-width: 451px) and (max-width: 767px) {
    font-size: 2rem;
  }
  @media (min-width: 320px) and (max-width: 450px) {
    font-size: 1.7rem;
    line-height: 40px;
  }
`;
export const IntroText = styled.p`
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: #4f5665;
  margin: auto;
  padding-top: 1.5rem;

  @media (min-width: 1000px) {
    font-size: 1.05rem;
  }
  @media (min-width: 320px) and (max-width: 450px) {
    font-size: 0.95rem;
    width: 90%;
    padding-top: 0.5rem;
  }
`;

export const CarousalContainer = styled.div`
  position: relative;
  min-height: 320px;
  width: 85%;
  margin: auto;

  @media (min-width: 768px) and (max-width: 920px) {
    width: 90%;
  }
  @media (min-width: 451px) and (max-width: 767px) {
    width: 95%;
    min-height: 300px;
    margin-top: 1.5rem;
  }
  @media (min-width: 320px) and (max-width: 450px) {
    /* border: 1px solid red; */
    width: 90%;
    margin: 0 auto;
    margin-top: 0rem;
    min-height: 300px;
  }
`;

export const EachCard = styled.div`
  /* width: 350px; */
  min-width: 330px;
  height: 200px;
  margin: 3rem 1.5rem;
  overflow: hidden;
  border: 2px solid #dddddd;
  box-sizing: border-box;
  border-radius: 10px;
  &:hover {
    border-color: #f53838;
    transition: all 150ms linear;
    background-color: #fff;
  }

  @media (min-width: 768px) and (max-width: 920px) {
    margin: 1rem 1rem;
    min-width: 230px;
    width: 230px;
    overflow: hidden;
    /* border: 1px solid green; */
  }
  @media (min-width: 451px) and (max-width: 767px) {
    margin: 1rem 1rem;
    min-width: 100px;
    width: 220px;
    height: 230px;
  }
  @media (min-width: 320px) and (max-width: 450px) {
    margin: 2.1rem 0rem;
    width: 290px;
    min-width: 100px;
    margin-left: 1rem;
  }
`;

export const LeftArrow = styled.div`
  width: 35px;
  height: 35px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  position: absolute;
  border: 1px solid #f53838;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  right: 4.8rem;
  &:hover {
    cursor: pointer;
  }
`;

export const RightArrow = styled.div`
  width: 35px;
  height: 35px;
  background: #f53838;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  position: absolute;
  bottom: 0;
  right: 2rem;
  &:hover {
    cursor: pointer;
  }
`;

export const SpanL = styled.span`
  width: 100%;
  height: 100%;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const SpanR = styled.span`
  width: 100%;
  border-radius: 50px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SpanImg = styled.img`
  object-fit: contain;
  height: 16px;
  width: 16px;
`;
export const TopBar = styled.div`
  display: flex;
  align-items: center;
  padding: 0.8rem 0.8rem 0;
`;
export const Img = styled.img`
  object-fit: "cover";
`;
export const Right = styled.div`
  display: flex;
  flex-direction: row;

  padding-left: 0.8rem;
  width: 100%;
  height: 45px;
`;
export const NameCon = styled.div`
  display: flex;
  width: 80%;
  flex-direction: column;
`;
export const StarDiv = styled.div`
  display: flex;
  align-items: center;
  /* width: 15%; */
  margin-top: -15px;
`;
export const StarValue = styled.span`
  color: #0b132a;
  padding-right: 0.5rem;
`;
export const SvgStar = styled.img`
  width: 20px;
  height: 20px;
`;

export const Name = styled.span`
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #0b132a;
  @media (min-width: 451px) and (max-width: 767px) {
    font-size: 1rem;
  }
`;
export const City = styled.span`
  color: #4f5665;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 0.84rem;
  line-height: 30px;
  margin-top: -5px;
  @media (min-width: 451px) and (max-width: 767px) {
    font-size: 0.6rem;
  }
`;
export const Content = styled.p`
  padding: 0 0.8rem 0.8rem;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 0.95rem;
  color: #0b132a;
  line-height: 22px;

  @media (min-width: 451px) and (max-width: 767px) {
    font-size: 0.9rem;
    line-height: initial;
    padding-top: 0.5rem;
  }
  @media (min-width: 320px) and (max-width: 450px) {
    font-size: 0.9rem;
  }
`;
