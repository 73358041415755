import React, { ReactElement } from "react";
import * as S from "./styles/carousal";
import { Carousel } from "@trendyol-js/react-carousel";

// interface Props {}
// {}: Props
let data = [
  {
    id: 1,
    name: "Viezh Robert",
    city: "Warsaw, Poland",
    star: 4.5,
    starImg: "/assets/svg/star.svg",
    img: "/assets/avatars/1.png",
    content:
      "“Wow... I am very happy to use this VPN, it turned out to be more than my expectations and so far there have been no problems. LaslesVPN always the best”.",
  },
  {
    id: 2,
    name: "Yessica Christy",
    city: "Shanxi, China",
    star: 4.5,
    starImg: "/assets/svg/star.svg",
    img: "/assets/avatars/2.png",
    content: "“I like it because I like to travel far and still can connect with high speed.”.",
  },
  {
    id: 3,
    name: "Kim Young Jou",
    city: "Seoul, South Korea",
    star: 4.5,
    starImg: "/assets/svg/star.svg",
    img: "/assets/avatars/3.png",
    content:
      "“This is very unusual for my business that currently requires a virtual private network that has high security.”.",
  },
  {
    id: 4,
    name: "Viezh Robert",
    city: "Warsaw, Poland",
    star: 4.5,
    starImg: "/assets/svg/star.svg",
    img: "/assets/avatars/1.png",
    content:
      "“Wow... I am very happy to use this VPN, it turned out to be more than my expectations and so far there have been no problems. LaslesVPN always the best”.",
  },
  {
    id: 5,
    name: "Yessica Christy",
    city: "Shanxi, China",
    star: 4.5,
    starImg: "/assets/svg/star.svg",
    img: "/assets/avatars/2.png",
    content: "“I like it because I like to travel far and still can connect with high speed.”.",
  },
  {
    id: 6,
    name: "Kim Young Jou",
    city: "Seoul, South Korea",
    star: 4.5,
    starImg: "/assets/svg/star.svg",
    img: "/assets/avatars/3.png",
    content:
      "“This is very unusual for my business that currently requires a virtual private network that has high security.”.",
  },
];

export default function Index(): ReactElement {
  return (
    <>
      <S.Container>
        <S.Heading>Trusted by Thousands of Happy Customer</S.Heading>
        <S.IntroText>
          These are the stories of our customers who have joined us with great pleasure when using
          this crazy feature.
        </S.IntroText>

        <S.CarousalContainer>
          <Carousel
            show={window.innerWidth < 451 ? 1 : 3}
            slide={2}
            swiping={true}
            // useArrowKeys={true}
            leftArrow={<LeftArrow />}
            rightArrow={<RightArrow />}
          >
            {data.map((i) => (
              <S.EachCard key={i.id}>
                <S.TopBar>
                  <S.Img src={i.img} width="52px" height="52px" />
                  <S.Right>
                    <S.NameCon>
                      <S.Name>{i.name}</S.Name>
                      <S.City>{i.city}</S.City>
                    </S.NameCon>
                    <S.StarDiv>
                      <S.StarValue>{i.star}</S.StarValue>
                      <S.SvgStar src={i.starImg} />
                    </S.StarDiv>
                  </S.Right>
                </S.TopBar>
                <S.Content>{i.content}</S.Content>
              </S.EachCard>
            ))}
          </Carousel>
        </S.CarousalContainer>
      </S.Container>
    </>
  );
}

function LeftArrow() {
  return (
    <S.LeftArrow>
      <S.SpanL>
        <S.SpanImg src="/assets/svg/left-arrow.svg" alt="" width="50px" height="50px" />
      </S.SpanL>
    </S.LeftArrow>
  );
}
function RightArrow() {
  return (
    <S.RightArrow>
      <S.SpanR>
        <S.SpanImg src="/assets/svg/right-arrow.svg" alt="" />
      </S.SpanR>
    </S.RightArrow>
  );
}
