import styled from "styled-components/macro";

export const OuterContainer = styled.div`
  /* height: 500px; */
  padding-bottom: 2rem;
  background-color: #f8f8f8;
`;
export const InnerCont = styled.div`
  display: flex;
  width: 80%;
  margin: auto;

  @media (min-width: 451px) and (max-width: 767px) {
    width: 90%;
    /* border: 1px solid red; */
    margin: 0 auto;
  }
  @media (min-width: 320px) and (max-width: 450px) {
    width: 95%;
  }
`;
export const Left = styled.div`
  width: 50%;

  @media (min-width: 768px) and (max-width: 920px) {
    width: 50%;
  }
  @media (min-width: 451px) and (max-width: 767px) {
    width: 45%;
  }
  @media (min-width: 320px) and (max-width: 450px) {
    /* border: 1px solid blue; */
    width: 35%;
  }
`;
export const LogoDiv = styled.div``;
export const LogoImg = styled.img`
  @media (min-width: 320px) and (max-width: 450px) {
    width: 110px;
    height: auto;
  }
`;

export const LeftTextCont = styled.div`
  width: 70%;

  @media (min-width: 451px) and (max-width: 767px) {
    width: 90%;
  }
  @media (min-width: 320px) and (max-width: 450px) {
    width: 100%;
  }
`;

export const LeftTxt = styled.p`
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #4f5665;

  @media (min-width: 320px) and (max-width: 450px) {
    line-height: 25px;
    font-size: 0.9rem;
  }
`;

export const Cpy = styled.div`
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  /* identical to box height, or 187% */
  text-align: left;
  color: #afb5c0;

  @media (min-width: 320px) and (max-width: 450px) {
    font-size: 0.8rem;
  }
`;

export const Right = styled.div`
  width: 55%;
  /* border: 1px solid green; */
  display: flex;
  @media (min-width: 320px) and (max-width: 450px) {
    width: 65%;
  }
`;

export const RightInner = styled.div`
  /* border: 1px solid red; */
  width: 30%;

  @media (min-width: 451px) and (max-width: 767px) {
    padding: 0.5rem;
  }
  @media (min-width: 320px) and (max-width: 450px) {
    width: 33%;
    padding-top: rem;
  }
`;
export const HeadingCont = styled.div`
  @media (min-width: 320px) and (max-width: 450px) {
    height: 40px;
  }
`;

export const RightHeading = styled.a`
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #0b132a;

  @media (min-width: 320px) and (max-width: 450px) {
    font-size: 0.9rem;
    line-height: 20px;
  }
`;

export const RightLiCont = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1.1rem;

  @media (min-width: 320px) and (max-width: 450px) {
    padding-top: 0rem;
  }
`;
export const Rightli = styled.span`
  cursor: pointer;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  padding: 0.1rem 0;
  line-height: 30px;
  transition: all 100ms linear;
  color: #4f5665;
  &:hover {
    font-weight: bold;
  }

  @media (min-width: 320px) and (max-width: 450px) {
    font-size: 0.8rem;
    line-height: 25px;
    padding: 0rem 0;
  }
`;
