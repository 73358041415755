import React, { useState, ReactElement } from "react";
import * as S from "./styles/plan-picker";

const data = [
  {
    id: 1,
    price: "Free",
    title: "Free Plan",
    img: "/assets/svg/gift1.svg",
    checkmark: "/assets/svg/checkmark.svg",
    features: [
      "Unlimited Bandwitch",
      "Encrypted Connection",
      "No Traffic Logs",
      "Works on All Devices",
    ],
  },
  {
    id: 2,
    price: "$9/mo",
    title: "Standard Plan",
    img: "/assets/svg/gift2.svg",
    checkmark: "/assets/svg/checkmark.svg",
    features: [
      "Unlimited Bandwitch",
      "Encrypted Connection",
      "Yes Traffic Logs",
      "Works on All Devices",
      "Connect Anywhere",
    ],
  },
  {
    id: 3,
    price: "$12/mo",
    title: "Premium Plan",
    img: "/assets/svg/gift3.svg",
    checkmark: "/assets/svg/checkmark.svg",
    features: [
      "Unlimited Bandwitch",
      "Encrypted Connection",
      "Yes Traffic Logs",
      "Works on All Devices",
      "Connect Anywhere",
      "Get New Features",
    ],
  },
];

function Index(): ReactElement {
  // Local state containing which plan is selected as per id.
  const [plan, setPlan] = useState<number | null>(null);

  // Handler function
  function setPlanFunc(param: number) {
    switch (param) {
      case 1:
        setPlan(1);
        break;
      case 2:
        setPlan(2);
        break;
      case 3:
        setPlan(3);
        break;
      default:
        setPlan(1);
    }
  }

  return (
    <S.Container>
      <S.Heading>Choose Your Plan</S.Heading>
      <S.IntroText>
        Let's choose the package that is best for you and explore it happily and cheerfully.
      </S.IntroText>

      <S.PlanContainer>
        {data.map((i) => (
          <S.EachPlan key={i.id} highlight={i.id > 0 && i.id === plan && true}>
            <S.InnerContainer>
              <S.ImgGift src={i.img} />
              <S.PlanTitle>{i.title}</S.PlanTitle>
              <S.LiContainer>
                {i.features.map((j) => (
                  <S.EachLi key={j}>
                    <S.LiImg src={i.checkmark} />
                    <S.LiSpan>{j}</S.LiSpan>
                  </S.EachLi>
                ))}
              </S.LiContainer>
              <S.BtnContainer>
                <S.PlanTypeTxt>{i.price}</S.PlanTypeTxt>
                <S.Button
                  onClick={() => setPlanFunc(i.id)}
                  highlight={i.id > 0 && i.id === plan && true}
                >
                  {plan === i.id ? "Selected" : "Select"}
                </S.Button>
              </S.BtnContainer>
            </S.InnerContainer>
          </S.EachPlan>
        ))}
      </S.PlanContainer>
    </S.Container>
  );
}

export default Index;
