import React, { ReactElement } from "react";
import * as S from "./styles/Navbar";

export default function Navbar(): ReactElement {
  return (
    <S.OuterCont>
      <S.Inner>
        <S.Logo src="/assets/svg/logo.svg" />
        <S.RightCont>
          <S.Linkcont>
            <S.Links>Pricing</S.Links>
            <S.Links>Testimonials</S.Links>
          </S.Linkcont>

          <S.BtnCont>
            <S.SignInBtn>Sign In</S.SignInBtn>
            <S.SignUpBtn>Sign Up</S.SignUpBtn>
          </S.BtnCont>
        </S.RightCont>
      </S.Inner>
    </S.OuterCont>
  );
}
