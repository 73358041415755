import styled from "styled-components/macro";

export const OuterCont = styled.div`
  /* border: 1px solid green; */
  display: flex;
  width: 80%;
  padding-bottom: 1rem;
  margin: auto;

  @media (min-width: 768px) and (max-width: 920px) {
    /* border: 1px solid red; */
    height: auto;
  }
  @media (min-width: 451px) and (max-width: 767px) {
    /* border: 1px solid blue; */
    width: 100%;
    padding-bottom: 0rem;
    height: auto;
  }
  @media (min-width: 320px) and (max-width: 450px) {
    /* border: 1px solid black; */
    width: 100%;
  }
`;

export const LeftSec = styled.div`
  width: 50%;
  margin-top: 2rem;

  @media (min-width: 768px) and (max-width: 920px) {
    margin-top: 0;
  }
  @media (min-width: 451px) and (max-width: 767px) {
    margin-top: 1rem;
  }
  @media (min-width: 320px) and (max-width: 450px) {
    /* border: 1px solid black; */
    margin-left: 0.5rem;
  }
`;

export const Heading = styled.h1`
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 50px;
  line-height: 70px;
  color: #0b132a;

  @media (min-width: 768px) and (max-width: 920px) {
    font-size: 2.1rem;
    line-height: 40px;
  }
  @media (min-width: 451px) and (max-width: 767px) {
    font-size: 1.1rem;
    line-height: 25px;
  }
  @media (min-width: 320px) and (max-width: 450px) {
    font-size: 1.5rem;
    line-height: 35px;
  }
`;

export const HeroText = styled.h4`
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: #4f5665;

  @media (min-width: 451px) and (max-width: 767px) {
    line-height: 25px;
    line-height: 20px;
  }
  @media (min-width: 320px) and (max-width: 450px) {
    font-size: 0.9rem;
    line-height: 20px;
  }
`;

export const Btn = styled.a`
  background: #f53838;
  border-radius: 10px;
  padding: 1rem 3rem;
  color: white;
  display: inline-block;
  font-weight: bold;
  box-shadow: 0 14px 20px 3px rgba(245, 56, 56, 0.35);
  position: relative;
  font-size: 1.1rem;
  transition: all 150ms linear;
  &:hover {
    cursor: pointer;
    transform: translateY(-5px);
  }

  @media (min-width: 768px) and (max-width: 920px) {
    font-size: 1rem;
    padding: 0.9rem 2.5rem;
  }
  @media (min-width: 451px) and (max-width: 767px) {
    padding: 0.9rem 1.5rem;
    font-weight: normal;
  }

  @media (min-width: 320px) and (max-width: 450px) {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
  }
`;

export const RightSec = styled.div`
  /* border: 1px solid blue; */
  width: 50%;
  overflow: hidden;
`;

export const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;

  @media (min-width: 768px) and (max-width: 920px) {
    object-fit: cover;
    margin-left: 0.1rem;
  }
  @media (min-width: 451px) and (max-width: 767px) {
    height: 290px;
    object-fit: cover;
    object-position: left;
  }

  @media (min-width: 320px) and (max-width: 450px) {
    /* height: 290px; */
    object-fit: cover;
    object-position: right;
  }
`;
