import styled from "styled-components/macro";

export const Container = styled.div`
  /* border: 1px solid blue; */
  padding-top: 3rem;
`;

export const Heading = styled.h4`
  font-family: Rubik;
  font-size: 35px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: 0em;
  margin: auto;
  text-align: center;
  color: #0b132a;
  font-family: "Rubik", sans-serif;

  @media (min-width: 768px) and (max-width: 920px) {
    font-size: 1.9rem;
  }
  @media (min-width: 451px) and (max-width: 767px) {
    font-size: 1.8rem;
  }

  @media (min-width: 320px) and (max-width: 450px) {
    font-size: 1.7rem;
  }
`;

export const IntroText = styled.p`
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: #4f5665;
  margin: auto;
  padding-top: 1rem;

  @media (min-width: 1000px) {
    font-size: 1.05rem;
    width: 520px;
  }

  @media (min-width: 320px) and (max-width: 450px) {
    font-size: 0.95rem;
    width: 90%;
    padding-top: 0.5rem;
  }
`;

export const PlanContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 900px;
  margin: 3rem auto 2rem;

  @media (min-width: 768px) and (max-width: 920px) {
    width: 90%;
  }
  @media (min-width: 451px) and (max-width: 767px) {
    width: 95%;
  }
  @media (min-width: 320px) and (max-width: 450px) {
    margin: 0 0;
    width: 100%;
    flex-direction: row;
    padding-top: 2rem;
    justify-content: space-around;
  }
`;

export const EachPlan = styled.div`
  background: #ffffff;
  border: ${(props) => (props.highlight ? "2px solid #f53838" : "2px solid #dddddd")};
  box-sizing: border-box;
  border-radius: 10px;
  min-width: 280px;
  width: 280px;
  overflow: hidden;
  &:hover {
    border: 2px solid #f53838;
    transition: all 150ms linear;
  }

  @media (min-width: 768px) and (max-width: 920px) {
    min-width: 220px;
    width: 200px;
  }
  @media (min-width: 451px) and (max-width: 767px) {
    width: 30%;
    min-width: 30%;
  }
  @media (min-width: 320px) and (max-width: 450px) {
    min-width: 100px;
    overflow: hidden;
    width: 30%;
  }
`;

export const InnerContainer = styled.div`
  margin: 4rem 2rem;
  text-align: center;

  @media (min-width: 768px) and (max-width: 920px) {
    margin: 3rem 1rem;
  }
  @media (min-width: 451px) and (max-width: 767px) {
    margin: 2rem 1rem;
  }
  @media (min-width: 320px) and (max-width: 450px) {
    margin: 1.5rem 0.5rem;
  }
`;

export const ImgGift = styled.img`
  @media (min-width: 451px) and (max-width: 767px) {
    height: 100px;
    width: auto;
  }
  @media (min-width: 320px) and (max-width: 450px) {
    height: 85px;
    width: auto;
  }
`;

export const PlanTitle = styled.h4`
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: #0b132a;
  padding-bottom: 1rem;

  @media (min-width: 451px) and (max-width: 767px) {
    padding-bottom: 0.5rem;
  }
  @media (min-width: 320px) and (max-width: 450px) {
    font-size: 1.1rem;
    padding-bottom: 0;
    line-height: initial;
    min-height: 45px;
  }
`;

export const LiContainer = styled.div`
  height: 200px;

  @media (min-width: 768px) and (max-width: 920px) {
    height: 230px;
  }
  @media (min-width: 451px) and (max-width: 767px) {
    height: 240px;
  }
  @media (min-width: 320px) and (max-width: 450px) {
    width: 100%;
    height: 240px;
  }
`;

export const EachLi = styled.div`
  text-align: left;
  display: flex;
  align-items: center;
`;

export const LiImg = styled.img`
  margin-right: 0.8rem;
  margin-left: 0.8rem;

  @media (min-width: 451px) and (max-width: 767px) {
    margin-right: 0.3rem;
    margin-left: 0.5rem;
  }
  @media (min-width: 320px) and (max-width: 450px) {
    height: 8px;
    width: auto;
    margin-right: 0.2rem;
    margin-left: 0rem;
  }
`;

export const LiSpan = styled.span`
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #4f5665;
  padding: 0.5rem 0;

  @media (min-width: 768px) and (max-width: 920px) {
    font-size: 0.9rem;
  }
  @media (min-width: 451px) and (max-width: 767px) {
    padding: 0.2rem 0;
    font-size: 1rem;
  }
  @media (min-width: 320px) and (max-width: 450px) {
    font-size: 0.8rem;
    padding: 0.45rem 0;
  }
`;

export const BtnContainer = styled.div``;

export const PlanTypeTxt = styled.p`
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  text-align: center;
  color: #0b132a;
  margin: 0.8rem;
  padding-top: 1rem;

  @media (min-width: 768px) and (max-width: 920px) {
    font-size: 18px;
  }
  @media (min-width: 451px) and (max-width: 767px) {
    font-size: 1.3rem;
  }
  @media (min-width: 320px) and (max-width: 450px) {
    padding-top: 1.2rem;
    font-size: 1.2rem;
  }
`;

export const Button = styled.div`
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  border: 2px solid #f53838;
  box-sizing: border-box;
  border-radius: 50px;
  padding: 0.6rem 0;
  width: 120px;
  margin: auto;
  color: ${(props) => (props.highlight ? "#ffffff" : "#f53838")};
  background-color: ${(props) => (props.highlight ? "#f53838" : "#ffffff")};

  &:hover {
    background-color: #f53838;
    color: #fff;
    transition: all 150ms linear;
    cursor: pointer;
  }

  @media (min-width: 768px) and (max-width: 920px) {
    width: 100px;
  }
  @media (min-width: 451px) and (max-width: 767px) {
    width: auto;
    padding: 0.4rem 0;
  }
  @media (min-width: 320px) and (max-width: 450px) {
    width: 100%;
    font-size: 0.9rem;
    padding: 0.4rem 0;
  }
`;
