import styled from "styled-components/macro";

export const OuterCont = styled.div`
  /* border: 1px solid red; */
  background: #f5f4f4;
  width: 100%;
`;
export const Inner = styled.div`
  padding: 1rem 0;
  width: 80%;
  display: flex;
  align-items: center;
  margin: auto;
  justify-content: space-between;
  @media (min-width: 768px) and (max-width: 920px) {
    width: 90%;
  }
  @media (min-width: 451px) and (max-width: 767px) {
    width: 100%;
  }
  @media (min-width: 320px) and (max-width: 450px) {
    width: 95%;
  }
`;
export const Logo = styled.img`
  height: 40px;
  @media (min-width: 320px) and (max-width: 450px) {
    height: 35px;
  }
`;
export const RightCont = styled.div`
  height: 100%;
  display: flex;
  width: 550px;
  justify-content: space-between;

  @media (min-width: 451px) and (max-width: 767px) {
    width: 60%;
    margin-right: 2rem;

    justify-content: space-between;
  }
  @media (min-width: 320px) and (max-width: 450px) {
    /* display: none; */
    width: auto;
    align-items: center;
  }
`;
export const Linkcont = styled.div``;

export const Links = styled.a`
  padding: 0 1rem;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #4f5665;
  transition: all 100ms linear;
  &:hover {
    color: #f53855;
    cursor: pointer;
  }

  @media (min-width: 451px) and (max-width: 767px) {
    padding: 0 0.4rem;
    padding-left: 0.5rem;
  }
  @media (min-width: 320px) and (max-width: 450px) {
    display: none;
  }
`;
export const BtnCont = styled.div``;
export const SignInBtn = styled.a`
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  padding: 0.5rem 1rem;
  padding-right: 2rem;
  cursor: pointer;

  @media (min-width: 451px) and (max-width: 767px) {
    padding: 0 0 !important;
  }
  @media (min-width: 320px) and (max-width: 450px) {
    padding: 0 0;
    font-size: 0.9rem;
  }
`;
export const SignUpBtn = styled.a`
  border: 1px solid #f53855;
  box-sizing: border-box;
  border-radius: 50px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  padding: 0.5rem 1.6rem;
  cursor: pointer;
  color: #f53855;
  transition: all 150ms linear;
  &:hover {
    color: white;
    background-color: #f53855;
  }

  @media (min-width: 768px) and (max-width: 920px) {
    font-size: 1rem;
    padding: 0.5rem 1.2rem;
  }
  @media (min-width: 451px) and (max-width: 767px) {
    padding: 0.3rem 0.6rem;
  }
  @media (min-width: 320px) and (max-width: 450px) {
    padding: 0.3rem 1rem;
    font-size: 0.9rem;
    margin-left: 0.5rem;
  }
`;
