import React, { ReactElement } from "react";
import { Hero, Navbar, CarousalHome, Footer, PlanPicker } from "../components";

// interface Props {}
// {}: Props
export default function HomePage(): ReactElement {
  return (
    <div>
      <Navbar />
      <Hero />
      <PlanPicker />
      <CarousalHome />
      <Footer />
    </div>
  );
}
