import React, { ReactElement } from "react";
import * as S from "./Styles/footerStyles";

export default function Index(): ReactElement {
  return (
    <S.OuterContainer>
      <S.InnerCont>
        <S.Left>
          <S.LogoDiv>
            <S.LogoImg src="/assets/svg/logo.svg" />
          </S.LogoDiv>
          <S.LeftTextCont>
            <S.LeftTxt>
              LaslesVPN is a private virtual network that has unique features and has high security.
            </S.LeftTxt>
          </S.LeftTextCont>
          <S.Cpy>©2020LaslesVPN</S.Cpy>
        </S.Left>

        <S.Right>
          <S.RightInner>
            <S.HeadingCont>
              <S.RightHeading>Product</S.RightHeading>
            </S.HeadingCont>
            <S.RightLiCont>
              <S.Rightli>Download</S.Rightli>
              <S.Rightli>Pricing</S.Rightli>
              <S.Rightli>Location</S.Rightli>
              <S.Rightli>Server</S.Rightli>
              <S.Rightli>Countries</S.Rightli>
              <S.Rightli>Blog</S.Rightli>
            </S.RightLiCont>
          </S.RightInner>

          <S.RightInner>
            <S.HeadingCont>
              <S.RightHeading>Engage</S.RightHeading>
            </S.HeadingCont>
            <S.RightLiCont>
              <S.Rightli>LaslesVPN?</S.Rightli>
              <S.Rightli>FAQ</S.Rightli>
              <S.Rightli>Tutorials</S.Rightli>
              <S.Rightli>About Us</S.Rightli>
              <S.Rightli>Privacy Policy</S.Rightli>
              <S.Rightli>Terms of Service</S.Rightli>
            </S.RightLiCont>
          </S.RightInner>

          <S.RightInner>
            <S.HeadingCont>
              <S.RightHeading>Earn Money</S.RightHeading>
            </S.HeadingCont>
            <S.RightLiCont>
              <S.Rightli>Affiliate</S.Rightli>
              <S.Rightli>Become Partner</S.Rightli>
            </S.RightLiCont>
          </S.RightInner>
        </S.Right>
      </S.InnerCont>
    </S.OuterContainer>
  );
}
