import React, { ReactElement } from "react";
import * as S from "./styles/Hero";

export default function Index(): ReactElement {
  return (
    <S.OuterCont>
      <S.LeftSec>
        <S.Heading>Want anything to be easy with LaslesVPN.</S.Heading>
        <S.HeroText>
          Provide a network for all your needs with ease and fun using LaslesVPN discover
          interesting features from us.
        </S.HeroText>
        <S.Btn>Get Started</S.Btn>
      </S.LeftSec>
      <S.RightSec>
        <S.Img src="/assets/svg/hero.svg" />
      </S.RightSec>
    </S.OuterCont>
  );
}
